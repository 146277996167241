import { FC } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

interface Props {
  text: string;
  apply: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

const ColorButton = styled(Button)<ButtonProps>(() => ({
  color: "#FFFFFF",
  fontFamily: "Montserrat, sans-serif",
  fontSize: 12,
  backgroundColor: "#4D748D",
  "&:hover": {
    backgroundColor: "#608dab",
  },
  "&:disabled": {
    backgroundColor: "#bebebe",
    color: "#5c5c5c",
  },
  borderRadius: 3,
  height: 38,
  width: 74,
  textTransform: "none",
}));

export const ApplyButton: FC<Props> = ({ text, apply, disabled }) => {
  return (
    <ColorButton onClick={apply} disabled={disabled}>
      {text}
    </ColorButton>
  );
};
