import { ChangeEvent, FC, useEffect, useState } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { TextField } from "@mui/material";
import Modal from "@mui/material/Modal";

import closeLogo from "../../assets/close-circle.png";
import { Service } from "../../services/api/service";
import { apiConfig } from "../../services/api/apiConfig";
import { useAuth } from "../../shared/hooks/auth-hook";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: theme.spacing(2, 3, 2),
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0 17px 40px 0 rgba(0,0,0,0.06), 0 2px 20px 0 rgba(0,0,0,0.1)",
  },
  closeButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 24,
    width: 24,
    backgroundImage: `url(${closeLogo})`,
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  title: {
    color: "#333333",
    fontFamily: "Oswald",
    fontSize: "24px",
    letterSpacing: "0",
    lineHeight: "34px",
    marginBottom: "10px",
    marginTop: "10px",
  },
  content: {
    marginTop: "25px",
    minWidth: "300px",
    maxWidth: "450px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 20px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    border: "1px solid #E1E1E1",
    padding: 10,
    width: "100%",
    color: "#666666",
    fontWeight: 400,
    fontFamily: "Montserrat",
    "&::placeholder": {
      color: "#666666",
      fontWeight: 400,
      fontFamily: "Montserrat",
      fontSize: 12,
    },
    "&:focus": {
      outline: "none",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
    gap: "15px 10px",
  },
  button: {
    boxSizing: "border-box",
    height: "38px",
    width: "110px",
    border: "1px solid #4D748D",
    borderRadius: "3px",
    cursor: "pointer",
  },
  buttonCancel: {
    backgroundColor: "#FFFFFF",
    color: "#4D748D",
  },
  buttonApply: {
    backgroundColor: "#4D748D",
    color: "#FFFFFF",
  },
  successMessage: {
    color: "#666666",
    fontFamily: "Montserrat",
    fontSize: "15px",
    fontWeight: "bold",
  },
  errorMessage: {
    color: "#c40014",
    fontFamily: "Montserrat",
    fontSize: "11px",
    fontWeight: "bold",
  },
}));

export const OverwriteDataSourceMappingsDialog: FC<Props> = ({ companyId, onClose }) => {
  const classes = useStyles();
  const { token } = useAuth();

  // To Fetch
  const [error, setError] = useState("");
  const [mappings, setMappings] = useState<Array<Mapping> | null>(null);

  // To Save
  const [saveErrorMessage, setSaveErrorMessage] = useState("");
  const [saveMessage, setSaveMessage] = useState("");
  const [saveOpen, setSaveOpen] = useState(false);

  useEffect(() => {
    let ignore = false;
    setMappings(null);
    fetchCompanyMappings(companyId, token).then(
      (result) => {
        if (!ignore) {
          setMappings(result);
        }
      },
      (reason) => {
        console.error(reason);
        setError("Couldn't load Company Data Source Mappings");
      }
    );
    return () => {
      ignore = true;
    };
  }, [companyId, token]);

  const handleChangeEntityIdChange = (id: number) => (e: ChangeEvent<HTMLInputElement>) => {
    if (mappings) {
      const newDataSourceMappings = mappings.map((mapping) => {
        if (mapping.dataSourceId !== id) return mapping;
        return { ...mapping, entityId: e.target.value };
      });

      setMappings(newDataSourceMappings);
    }
  };

  const handleSubmit = () => {
    setSaveOpen(true);

    saveCompanyMappings(companyId, token, mappings ?? []).then(
      () => {
        setSaveMessage("Company Data Source Mappings are updated!");
      },
      (reason) => {
        console.error(reason);
        const message = (typeof reason === "string" ? reason : reason?.message) ?? "";
        setSaveErrorMessage(message);
        setSaveOpen(false);
      }
    );
  };

  const handleSaveMessageDialogClose = () => {
    setSaveOpen(false);
    onClose();
  };

  return (
    <>
      <div className={classes.container}>
        {error && (
          <div>
            <div>
              <button onClick={onClose} className={classes.closeButton} />
              {error}
            </div>
          </div>
        )}
        {!error && !mappings && (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        {!error && mappings && (
          <>
            <button onClick={onClose} className={classes.closeButton} />
            <div className={classes.title}>Edit Data Sources mappings</div>
            <div className={classes.content}>
              {mappings.map(({ dataSourceId, dataSourceName, entityId }) => (
                <div className={classes.formGroup} key={dataSourceId}>
                  <TextField
                    id={`data-source-mapping-${dataSourceId}`}
                    type="text"
                    label={`${dataSourceName} Entity ID`}
                    placeholder={""}
                    value={entityId}
                    variant="outlined"
                    size="small"
                    inputProps={{ style: { fontSize: 14, fontFamily: "Montserrat", color: "#333333" } }}
                    onChange={handleChangeEntityIdChange(dataSourceId)}
                  />
                </div>
              ))}
              <div className={classes.formGroup} style={{ marginTop: "15px" }}>
                <span className={classes.errorMessage}>{saveErrorMessage}</span>
              </div>
              <div className={classes.actions}>
                <button className={`${classes.button} ${classes.buttonCancel}`} type="button" onClick={onClose}>
                  Cancel
                </button>
                <button className={`${classes.button} ${classes.buttonApply}`} type="submit" onClick={handleSubmit}>
                  Apply
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal open={saveOpen} onClose={handleSaveMessageDialogClose} hideBackdrop={!saveMessage}>
        <div className={classes.container}>
          {saveMessage ? (
            <div className={classes.content}>
              <span className={classes.successMessage}>{saveMessage}</span>
              <div className={classes.actions}>
                <button
                  className={`${classes.button} ${classes.buttonApply}`}
                  type="submit"
                  onClick={handleSaveMessageDialogClose}
                >
                  Ok
                </button>
              </div>
            </div>
          ) : (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

interface DBMapping {
  id: number;
  companyId: number;
  dataSourceId: number;
  dataSourceName: string;
  dataSourceEndpoint: string;
  entityId: string;
}

interface Mapping {
  dataSourceId: number;
  dataSourceName: string;
  entityId: string;
}

interface Props {
  companyId: number;
  onClose: () => void;
}

const fetchCompanyMappings = async (companyId: number, token: string | null) => {
  if (!token) {
    throw new Error("Not Authorized!");
  }

  if (!companyId) {
    throw new Error("Missing required param companyId");
  }

  const res = await Service.get(`${apiConfig.companiesDataManager}/${companyId}/data-source-mappings`, token);
  if (res.status === 200) {
    const dbMappings: DBMapping[] = res.data.mappings;
    const dataSources = res.data.dataSources as any[];

    const mappings: Mapping[] = dataSources.map((ds) => {
      const mapping = dbMappings.find((m) => m.dataSourceId === ds.id);
      return {
        dataSourceId: ds.id,
        dataSourceName: ds.name,
        entityId: mapping ? mapping.entityId : "",
      };
    });

    return mappings;
  }

  throw new Error("Couldn't load Company Data Source Mappings");
};

const saveCompanyMappings = async (companyId: number, token: string | null, mappings: Mapping[]) => {
  if (!token) {
    throw new Error("Not Authorized!");
  }

  if (!companyId) {
    throw new Error("Missing required param companyId");
  }

  try {
    const res = await Service.put(
      `${apiConfig.companiesDataManager}/${companyId}/data-source-mappings`,
      token,
      mappings
    );

    if (res.status !== 200) {
      throw new Error(`"Couldn't update Company Data Source Mappings. ${res.data}`);
    }
  } catch (error) {
    console.error(error);
    if ((error as any)?.response?.data?.message) {
      throw new Error((error as any)?.response?.data?.message);
    }
    throw new Error("Couldn't update Company Data Source Mappings");
  }
};
