import { FC, Fragment } from "react";
import { CompanyViewTable } from "../../companyViewTable";
import { makeStyles } from "@material-ui/core";
import { EditComponent } from "../industryCodesTable/editComponent";
import { ICompany, IIndustry } from "../../../../interfaces";
import { getHighestPriorityDataIndustry, getHighestPriorityDataSecondaryIndustries } from "../helpers";
import { IEditFieldModalProp, ISingleData } from "../../../company-edit/SingleFieldEditModal";
import { ISelectData } from "../../../../pages/companyView/types";
import { IListData } from "../../../company-edit/ListFieldEditModal";
import { ITableData } from "../../../company-edit/TableFieldEditModal";
import { formatIndustry } from "../../../../utils/industries";
import { ICompanyEditModalFunctions } from "../../../../utils/companyEditModalFunctions";

type Industry = {
  name: string;
  primary?: boolean;
}

interface Props {
  data: ICompany;
  isEdit?: boolean;
  editModalFunctions: ICompanyEditModalFunctions;
  editDataForFields: Record<string, IEditFieldModalProp<ISelectData | ISingleData | IListData | ITableData> | null>;
}

const useStyles = makeStyles(() => ({
  empty: {
    width: "100%",
    color: "#333",
    fontSize: "18px",
    lineHeight: "24px",
    fontFamily: "Montserrat",
    marginLeft: "5px",
  },
  industryType: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    alignItems: "center",
  },
  chip: {
    backgroundColor: "#96ee53",
    fontFamily: "Montserrat",
    borderRadius: "8px",
    padding: "4px",
    alignSelf: "center",
  },
}));

export const IndustryTypesTable: FC<Props> = ({data, isEdit, editModalFunctions, editDataForFields}) => {
  const classes = useStyles();

  const primaryIndustryInEditForm = getHighestPriorityDataIndustry(editDataForFields);
  const primaryIndustryField = primaryIndustryInEditForm ? primaryIndustryInEditForm.data : data.primaryIndustry ?? null;

  const primaryIndustry = primaryIndustryField ? formatIndustry(primaryIndustryField as IIndustry) : null;

  const secondaryIndustries = getHighestPriorityDataSecondaryIndustries(editDataForFields) ?? data.secondaryIndustries?.map(formatIndustry);

  const secIndustries = !secondaryIndustries?.length ? [] : secondaryIndustries.map((i) => ({name: String(i.value), primary: false}))
  const primIndustry = primaryIndustry?.value ? [{name: primaryIndustry.value, primary: true}] : [];
  const industries = [...primIndustry, ...secIndustries];

  const formatCell = (industry: Industry) => {
    if (!industry.primary) return industry.name;

    return (
      <div className={classes.industryType}>
        <div>
          {industry.name}
        </div>
        <div className={classes.chip}>
          Primary
        </div>
      </div>
    )
  };
  const tableRows =  industries.map((industry) => {
    const editAction = industry.primary ? editModalFunctions.openIndustryEdit : editModalFunctions.openSecondaryIndustriesEdit;
    return {
      rowSpan: industries.length -1,
      rowTitle: formatCell(industry),
      editComponent: isEdit ? <EditComponent editAction={() => editAction()} /> : <Fragment />
    }
  })

  if (
    !data.primaryIndustry &&
    !getHighestPriorityDataIndustry(editDataForFields) &&
    !data.secondaryIndustries &&
    !getHighestPriorityDataSecondaryIndustries(editDataForFields)
  ) {
    return <div className={classes.empty}>No Data.</div>;
  }

  return (
  <CompanyViewTable
    title="Industries"
    headers={[
      {title: "", width: "70%"},
      {title: "", width: "10%"},
      {title: "", width: "10%"},
      {title: "", width: "10%"}
    ]}
    rows={tableRows}
  />
  )
}