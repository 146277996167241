import { FC, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";

import { ICompany } from "../../../interfaces";
import { ICompanyEditModalFunctions } from "../../../utils/companyEditModalFunctions";
import { useAuth } from "../../../shared/hooks/auth-hook";
import { Maps } from "../googleMaps/map";
import { Banner } from "../../banner";
import { Avatar } from "../../avatar";
import { OverwriteDataSourceMappingsDialog } from "../../company-overwrite/overwriteDataSourceMappingsDialog";
import { ShowDataSources } from "../ShowDatasources";
import { formatHqAddress } from "./helpers";
import { HqAddress } from "./types";

import { useStyles } from "./styles";

export interface CompanyBannerProps {
  data: any;
  isEdit: boolean;
  setIsEdit: () => void;
  preview: boolean;
  editModalFunctions: ICompanyEditModalFunctions;
  getHighestPriorityData: (property_name: string) => any;
  getHighestPriorityDataHQAddress: () => any;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
}

export const CompanyBanner: FC<CompanyBannerProps> = ({
  data,
  isEdit,
  setIsEdit,
  preview,
  editModalFunctions,
  getHighestPriorityData,
  getHighestPriorityDataHQAddress,
  getDataSource,
}) => {
  const classes = useStyles();
  const [domainURL, setDomainURL] = useState<string>("");

  const [openOverwriteMappingsDialog, setOpenOverwriteMappingsDialog] = useState(false);
  const handleOpenOverwriteMappingsDialog = () => setOpenOverwriteMappingsDialog(true);
  const handleCloseOverwriteMappingsDialog = () => setOpenOverwriteMappingsDialog(false);
  const { hasPermission } = useAuth();

  const hqAddress: HqAddress = getHighestPriorityDataHQAddress()
    ? getHighestPriorityDataHQAddress()
    : formatHqAddress(data);

  useEffect(() => {
    data?.domain_primary?.value?.startsWith("http://") || data?.domain_primary?.value?.startsWith("https://")
      ? setDomainURL(data?.domain_primary?.value)
      : setDomainURL("http://" + data?.domain_primary?.value);
  }, [data?.domain_primary?.value]);

  return (
    <Banner buttonTitle="Back to all companies" location={-1}>
      <div style={{ display: "flex", position: "relative", width: "100%" }}>
        <div
          style={{ display: "flex", flexDirection: "row", position: "relative", zIndex: 2, background: "transparent" }}
        >
          <div>
            <ShowDataSources
              getDataSource={getDataSource}
              data={
                getHighestPriorityData("logo")
                  ? { sourceId: getHighestPriorityData("logo")?.sourceId }
                  : { sourceId: data?.logo?.sourceId }
              }
            >
              <div onClick={editModalFunctions.openLogoEdit}>
                <Avatar
                  logo={data?.logo?.value}
                  name={data?.name?.value}
                  style={{
                    display: "grid",
                    placeItems: "center",
                    margin: "10px 30px 50px 55px",
                    width: 240,
                    height: 240,
                    padding: 15,
                    fontSize: "36px",
                    ...(isEdit ? { cursor: "pointer" } : {}),
                  }}
                />
              </div>
            </ShowDataSources>
          </div>
          <div className={classes.companyInfo}>
            <ShowDataSources
              getDataSource={getDataSource}
              data={
                getHighestPriorityData("name")
                  ? { sourceId: getHighestPriorityData("name")?.sourceId }
                  : { sourceId: data?.name?.sourceId }
              }
            >
              <span
                className={isEdit ? `${classes.companyName} ${classes.hover}` : classes.companyName}
                onClick={editModalFunctions.openNameEdit}
                style={{ cursor: isEdit ? "pointer" : "default" }}
              >
                {getHighestPriorityData("name") ? getHighestPriorityData("name")?.value : data?.name?.value}
              </span>
            </ShowDataSources>
            <span className={classes.companyIndustry}>{(data as ICompany)?.primaryIndustry?.name}</span>
            <a href={domainURL} target="_blank" rel="noreferrer" className={classes.companyLink}>
              {data?.domain_primary?.value?.replace(/^https?\:\/\//i, "") || data?.name?.value || "no_domain"}
            </a>
            <div className={classes.companySubInfo}>
              {data && data.parentCompany ? (
                <div className={classes.subsidiaryOf}>
                  <span className={classes.subsidiaryOfTitle}>Subsidiary Of</span>
                  {data.parentCompany.id ? (
                    <a
                      href={`/companies/${data.parentCompany.id}`}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.companyLink}
                    >
                      {data.parentCompany.name}
                    </a>
                  ) : (
                    <span>{data.parentCompany.name}</span>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <div className={classes.companyInfoWrapper}>
            <ShowDataSources data={{ sourceId: Number(hqAddress.sourceId) }} getDataSource={getDataSource}>
              <div style={{ marginRight: 30 }} className={classes.contactParent}>
                <span className={classes.addressTitle}>Address</span>
                <span
                  className={isEdit ? `${classes.address} ${classes.hover}` : classes.address}
                  onClick={editModalFunctions.openHeadquarterEdit}
                  style={{ cursor: isEdit ? "pointer" : "default" }}
                >
                  {hqAddress.value}
                </span>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${hqAddress.value}`}
                  target="_blank"
                  className={classes.companyLink}
                >
                  See in Google Map
                </a>
              </div>
            </ShowDataSources>
            <ShowDataSources data={{ sourceId: data?.phone_numbers?.sourceId }} getDataSource={getDataSource}>
              <div className={classes.contactParent}>
                <span className={classes.addressTitle}>Phone Numbers</span>
                <div
                  className={isEdit ? `${classes.contactParent} ${classes.hover}` : classes.contactParent}
                  onClick={editModalFunctions.openPhoneNumberEdit}
                  style={{ cursor: isEdit ? "pointer" : "default", maxHeight: "26px" }}
                >
                  <p className={classes.phoneNumber}>
                    {getHighestPriorityData("phone")
                      ? getHighestPriorityData("phone")?.value
                      : data?.phone_numbers?.value
                      ? data?.phone_numbers?.value
                      : isEdit
                      ? ""
                      : "No Data"}
                  </p>
                </div>
              </div>
            </ShowDataSources>
          </div>
        </div>

        <div className={classes.mapsParent}>
          <div className={classes.fadeImageContainer}>
            <Maps address={String(hqAddress.value)} className={classes.fadeImage} />
            <div className={classes.gradientOverlay}></div>
          </div>
          <div className={`${classes.buttons} no-print`}>
            {isEdit && !preview ? (
              <button
                className={`${classes.button} ${classes.editButton}`}
                style={{ width: "149px" }}
                title="action not implemented"
              >
                Enhanced pull
              </button>
            ) : (
              <>
                <button className={`${classes.button} ${classes.historyButton}`} title="action not implemented">
                  Show version history
                </button>
                {hasPermission("Company.ReadWrite") && (
                  <button className={`${classes.button} ${classes.editButton}`} onClick={setIsEdit}>
                    Edit company profile
                  </button>
                )}

                {hasPermission("Company.ReadWrite.All") && (
                  <button
                    className={`${classes.button} ${classes.editButton}`}
                    onClick={handleOpenOverwriteMappingsDialog}
                  >
                    Edit Data Sources mappings
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal open={openOverwriteMappingsDialog} onClose={handleCloseOverwriteMappingsDialog}>
        <OverwriteDataSourceMappingsDialog
          onClose={handleCloseOverwriteMappingsDialog}
          companyId={data.id}
        ></OverwriteDataSourceMappingsDialog>
      </Modal>
    </Banner>
  );
};
