import { makeStyles } from "@material-ui/core";
import { FC } from "react";
import nFormatter from "../../utils/numberToStringConverter";
import ToolTip from "./toolTip";
import check from "../../assets/check.svg";
import cross from "../../assets/cross.svg";
import dateFormatter from "../../utils/dateConverter";
import { ShowDataSources } from "./ShowDatasources";
import { tooltipClasses } from "@mui/material/Tooltip";
import { currencyFormatter } from "../../utils/currencyFormatter";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "10px",
    lineHeight: "14px",
    fontFamily: "Montserrat",
    "& a": {
      color: "#4D748D",
    },
  },
  featureCard: {
    // Size
    boxSizing: "content-box",
    padding: "10px 10px",
    width: "220px",
    height: "65px",
    // Layout
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "10px",
    // Font & Colors
    backgroundColor: "#fff",
    color: "#333",
    // Border
    borderRadius: 5,
    boxShadow: "0 2px 20px 0 rgba(0,0,0,0.1)",
    border: "2px solid transparent",
    // Hover
    "&:hover": {
      borderColor: "#75AED2",
    },
  },
  featureCardDisabled: {
    border: "1px solid #a6a6a6",
    backgroundColor: "#a6a6a6",
    opacity: "0.6",
    "&:hover": {
      border: "1px solid #a6a6a6",
    },
  },
  left: {
    lineHeight: "0",
  },
  right: {
    flexGrow: 1,
    // height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  logo: {
    width: "36px",
    height: "36px",
  },
  title: {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "20px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  contentWrapper: {
    position: "relative",
    fontFamily: "Oswald",
    fontSize: "14px",
    lineHeight: "20px",
    flexGrow: 1,
  },
  contentTooltipPopper: {
    backgroundColor: "#555",
    color: "#fff",
    borderRadius: "6px",
  },
  contentTooltipTooltip: {
    maxWidth: "250px",
    textAlign: "center",
    fontFamily: "Oswald !important",
    fontSize: "14px !important",
    lineHeight: "16px !important",
    backgroundColor: "#555 !important",
  },
  content: {},
}));

type FontSize = "xs" | "s" | "m" | "l";

const ContentSizeToStyles: Record<FontSize, { fontSize: string; lineHeight: string }> = {
  xs: {
    fontSize: "12px",
    lineHeight: "14px",
  },
  s: {
    fontSize: "14px",
    lineHeight: "14px",
  },
  m: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  l: {
    fontSize: "20px",
    lineHeight: "24px",
  },
};

interface Props {
  title: string;
  titleSize?: FontSize;
  contentSize?: FontSize;
  contentTooltip?: boolean;
  data: any;
  isDataBoolean?: boolean;
  link?: boolean;
  linkTitle?: string;
  logo?: any;
  seperator?: boolean;
  showYear?: boolean;
  showQuarter?: boolean;
  date?: boolean;
  isEdit: boolean;
  disabled?: boolean;
  clicked?: () => void;
  getDataSource: (id: number) => { name: string; endpoint: string; id: number };
  sourceIds?: number[];
  currency?: string;
  decimalYearPart?: boolean;
}

export const FeatureCard: FC<Props> = ({
  title,
  titleSize,
  contentSize,
  contentTooltip,
  logo,
  data,
  showYear,
  showQuarter,
  seperator,
  date,
  isEdit,
  disabled,
  clicked,
  getDataSource,
  sourceIds,
  currency,
  isDataBoolean,
  link,
  linkTitle,
  decimalYearPart,
}) => {
  const classes = useStyles();

  const dataSource = data && data.sourceId && getDataSource(data?.sourceId);

  const dataSourceTooltip = dataSource ? `This data is from ${dataSource.name}` : "No data source is found";
  const tooltipWhenDisabled = "This field cannot be edited";

  const tooltip = isEdit && disabled ? tooltipWhenDisabled : dataSourceTooltip;

  const fontSize = contentSize || "l";
  const titleFontSize = titleSize || "m";

  const contentStyle = ContentSizeToStyles[fontSize];
  const titleStyle = ContentSizeToStyles[titleFontSize];
  const yearStr =
    showYear && data.year !== undefined
      ? ` (${showQuarter && data.quarter ? `Q${data.quarter} ` : ""}${decimalYearPart ? data.year % 100 : data.year})`
      : "";
  return (
    <>
      {/* Tooltip that shows data source for summary */}
      <ShowDataSources
        data={sourceIds ? sourceIds.map((item) => ({ sourceId: item })) : []}
        getDataSource={getDataSource}
      >
        {/* If user click this div(card) and if we are in edit mode and preview is not active, we open Modal for source selection */}
        <div
          className={
            isEdit && disabled ? `${classes.featureCard} ${classes.featureCardDisabled}` : `${classes.featureCard}`
          }
          onClick={clicked}
          style={{ cursor: isEdit ? "pointer" : "default" }}
        >
          {logo && (
            <div className={classes.left}>
              <img src={logo} alt="feature" className={classes.logo} />
            </div>
          )}
          <div className={classes.right}>
            <div className={classes.title} style={titleStyle}>
              {title}
            </div>
            <div className={classes.contentWrapper} style={contentStyle}>
              <ToolTip
                disableHoverListener={!contentTooltip || !data?.value?.length}
                disableFocusListener={!contentTooltip || !data?.value?.length}
                classes={{
                  popper: classes.contentTooltipPopper,
                  tooltip: classes.contentTooltipTooltip,
                }}
                placement="bottom-start"
                title={
                  <span>
                    {data ? (
                      <>
                        {seperator ? nFormatter(data.value) : data.details || data.value}
                        {yearStr}
                      </>
                    ) : null}
                  </span>
                }
              >
                {isDataBoolean ? (
                  <BooleanContent data={data} isEdit={isEdit} disabled={disabled}></BooleanContent>
                ) : link ? (
                  <LinkContent data={data} isEdit={isEdit} disabled={disabled} linkTitle={linkTitle}></LinkContent>
                ) : data?.stock ? (
                  <div>
                    <div style={ContentSizeToStyles["l"]}>{data.stock.ticker}</div>
                    <div
                      style={{
                        ...ContentSizeToStyles["xs"],
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {data.stock.exchange}
                    </div>
                  </div>
                ) : (
                  <span>
                    {data?.value && (Array.isArray(data?.value) ? data?.value?.length : true)
                      ? currency
                        ? `${currencyFormatter.format(parseInt(data.value))}${yearStr}`
                        : seperator
                        ? `${nFormatter(data?.value)}${yearStr}`
                        : date
                        ? `${dateFormatter(data?.value)}`
                        : `${data?.value}${yearStr}`
                      : data?.range
                      ? data?.range?.name
                      : isEdit && !disabled
                      ? " Add data"
                      : ""}
                  </span>
                )}
              </ToolTip>
            </div>
          </div>
        </div>
      </ShowDataSources>
    </>
  );
};

type BooleanContentProps = {
  data: { value: any };
  isEdit: boolean;
  disabled?: boolean;
};

function BooleanContent({ data, isEdit, disabled }: BooleanContentProps) {
  if (data === undefined) {
    return null;
  }

  if (data === null) {
    return <div>{isEdit && !disabled ? " Add data" : ""}</div>;
  }

  return (
    <div>
      <img src={data?.value ? check : cross} alt=" icon" style={{ height: "20px", width: "20px" }} />
    </div>
  );
}

type LinkContentProps = {
  data: { value: any };
  isEdit: boolean;
  disabled?: boolean;
  linkTitle?: string;
};

function LinkContent({ data, isEdit, disabled, linkTitle }: LinkContentProps) {
  if (data === undefined) {
    return null;
  }

  if (data === null) {
    return <div>{isEdit && !disabled ? "Add data" : ""}</div>;
  }

  return (
    <a href={data.value.startsWith("http") ? data.value : "https://" + data.value} target="_blank" rel="noreferrer">
      {linkTitle || "Link"}
    </a>
  );
}
