import { FC, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import nFormatter from "../../utils/numberToStringConverter";
import { SectionCard } from "../../components/company-view/sectionCard";
import dateFormatter from "../../utils/dateConverter";
import { NavigationBar } from "../../components/navigation";
import { CompanyBanner } from "../../components/company-view/companyBanner";
import { CompanyOverview } from "../../components/company-view/company-overview/companyOverview";
import { AlternativeNames } from "../../components/company-view/alternative-names/alternativeNames";
import { InsightsSection } from "../../components/company-view/insights/insightsSection";
import { RevenueRange } from "../../components/company-view/revenue-range/revenueRange";
import { News } from "../../components/company-view/news/news";
import { AdditionalLinks } from "../../components/company-view/additional-links/additionalLinks";
import { ModalComponent } from "../../components/modalView";
import ExportIcon from "../../assets/export.svg";
import { apiConfig } from "../../services/api/apiConfig";
import { Service } from "../../services/api/service";
import { useAuth } from "../../shared/hooks/auth-hook";
import { ApplyButton } from "../../components/applyButton";
import { ClearFilters } from "../../components/clearfilters";
import { DropdownMenu } from "../../components/dropdownMenu";
import { InsightItem } from "../../components/company-view/insights/insight.model";
import {
  IEditFieldModalProp,
  ISingleData,
  SingleFieldEditModal,
} from "../../components/company-edit/SingleFieldEditModal";
import { IListData, ListFieldEditModal } from "../../components/company-edit/ListFieldEditModal";
import { ITableData, TableFieldEditModal } from "../../components/company-edit/TableFieldEditModal";
import { EditSubsidiariesModal } from "../../components/company-edit/edit-subsidiaries/edit-subsidiaries-modal";
import EditModalFunctions from "../../utils/companyEditModalFunctions";
import { AnnualReportsTable } from "../../components/company-view/annualReportsTable";
import { ICompany, IDataItem, IInsight, ISelectItem, IStoreSelectItem } from "../../interfaces";
import { CompanyDomainsTable } from "../../components/company-view/companyDomains";

import { ExcelExport } from "../../utils/exportToExcel";

import { useStyles } from "./styles";

import { fetchData } from "./helpers";

import {
  COMPANY_PERSON_FIELDS,
  EDIT_MODAL_INITIAL_STATE,
  FIELDS_INITIAL_STATE,
  NAV_SECTIONS,
  PERSON_PROPERTY_NAMES,
} from "./constants";
import { IOpenedModal, ISelectData } from "./types";
import { EditStockAndOwnershipTypeModal } from "../../components/company-edit/edit-stock-and-ownership-type/edit-stock-and-ownership-type-modal";
import type { EditData as StockAndOwnershipTypeEditData } from "../../components/company-edit/edit-stock-and-ownership-type/edit-data";

// type FieldsEditData = {
//   stock: StockAndOwnershipTypeEditData["stock"] | null;
//   ownershipType: StockAndOwnershipTypeEditData["ownershipType"] | null;
// } & Record<
//   Exclude<string, "stock" | "ownershipType">,
//   IEditFieldModalProp<ISingleData | IListData | ITableData | ISelectData> | null
// >;

export const CompanyView: FC = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [companyData, setCompanyData] = useState<Record<string, any>>({});
  const [dataSources, setDataSources] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const companyRowData: any = useLocation(); //data that is coming from company row view and edit buttons. initial state
  const excelExport = new ExcelExport();

  const [isEdit, setIsEdit] = useState<boolean>(companyRowData.state?.edit || false);
  const [preview, setPreview] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [messageModal, setMessageModal] = useState<boolean>(false);
  const itemRefs = useRef<any>([null]);

  const [editDataForFields, setEditDataForFields] =
    useState<Record<string, IEditFieldModalProp<ISingleData | IListData | ITableData | ISelectData> | null>>(
      FIELDS_INITIAL_STATE
    );

  const [selectedItemsForStores, setSelectedItemsForStores] = useState<Record<string, number[]>>({
    country: [],
    state: [],
    store_type: [],
  });

  const [storeTypes, setStoreTypes] = useState<ISelectItem[]>([]);
  const [openedModal, setOpenedModal] = useState<IOpenedModal>(EDIT_MODAL_INITIAL_STATE);
  const { token } = useAuth();

  const editModalFunctions = useMemo(() => new EditModalFunctions(isEdit, setOpenedModal), [isEdit]);

  const fetchStoresItems = async (path: string) => {
    const response = await Service.get(path, token as string);
    return response?.data;
  };

  const scrollToSection = (id: string) => {
    const item = itemRefs.current.filter((item: any) => item.id === id);
    window.scrollTo({
      behavior: "smooth",
      top: item[0]?.offsetTop - 400,
    });
  };

  const seePreview = () => {
    setPreview((preview) => !preview);
    setIsEdit((preview) => !preview);
  };

  const edit = () => {
    setIsEdit(true);
    setPreview(false);
  };

  const cancel = () => {
    setEditDataForFields(FIELDS_INITIAL_STATE);
    setIsEdit(false);
    setPreview(false);
    setOpenedModal({ ...openedModal, isVisible: false });
    setRefresh(!refresh);
  };

  const save = () => {
    Service.patch(`${apiConfig.companiesDataManager}/${id as string}`, token as string, editDataForFields)
      .then((response: any) => {
        setIsEdit(false);
        setPreview(false);
        setEditDataForFields(FIELDS_INITIAL_STATE);
        setRefresh(!refresh);
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message);
        setMessageModal(true);
      });
  };

  const getCompanyField = async (property_name: string) => {
    const get = await Service.get(`${apiConfig.companiesDataManager}/${id}/${property_name}`, token as string);
    return get.data;
  };

  const getDataSource = (id: number | undefined) => {
    if (id) return dataSources.find((dataSource: any) => dataSource.id === id);
    else return "";
  };

  const cancelEditModal = () => setOpenedModal({ ...openedModal, isVisible: false });

  const handleSaveEdit = async (
    property_name: string,
    data: IEditFieldModalProp<ISingleData | IListData | ITableData | ISelectData>,
    additional_data?: Record<string, number[] | IEditFieldModalProp<ISelectData>>
  ) => {
    if (property_name === "naceClassification") {
      for (const item of data.data as any[]) {
        if (!item.code) {
          item.code = item.value;
          item.sourceId = item.data_source_id;
        }
      }
    }
    if (property_name === "naicsClassification") {
      for (const item of data.data as any[]) {
        if (!item.code) {
          item.code = item.value;
          item.sourceId = item.data_source_id;
        }
      }
    }
    if (property_name === "isicClassification") {
      for (const item of data.data as any[]) {
        if (!item.code) {
          item.code = item.value;
          item.sourceId = item.data_source_id;
        }
      }
    }

    const newEditDataForFields = { ...editDataForFields, [property_name]: data };
    if (PERSON_PROPERTY_NAMES.includes(property_name)) {
      const data = newEditDataForFields[property_name]?.data as Array<any>;
      if (data !== null) {
        for (const person of data) {
          if (!person.full_name) person.full_name = person.first_name + " " + person.last_name;
        }
      }
    }

    if (additional_data) {
      const selected_key = Object.keys(additional_data)[0];
      const selected_data = additional_data[selected_key];
      if (property_name.includes("store_num")) {
        //TO DO update this part after shopper count is merged
        handleSelectDataSave(selected_key, selected_data as number[]);
      } else {
        newEditDataForFields[selected_key] = selected_data as IEditFieldModalProp<ISelectData>;
      }
    }
    setEditDataForFields(newEditDataForFields);
  };

  const handleSaveStockAndOwnershipType = (value: StockAndOwnershipTypeEditData) => {
    const { ownershipType, stock } = value;
    setEditDataForFields((prevState) => {
      return {
        ...prevState,
        ownershipType,
        stock,
      } as any;
    });
  };

  const handleSelectDataSave = (property_name: string, data: number[] | string[]) => {
    Service.patch(`${apiConfig.storeSelectedItems}/${companyData?.id}`, token as string, {
      by: property_name,
      showing_ids: data,
    }).then((response: any) => {
      setSelectedItemsForStores({ ...selectedItemsForStores, [property_name]: [...data] as number[] });
      setRefresh(!refresh);
    });
  };

  const getHighestPriorityYearlyData = (property_name: string, key?: string, item_id?: number) => {
    let new_data: ITableData[] = [];
    if (
      property_name.includes("store_num") ||
      property_name.includes("revenue") ||
      property_name === "shopper_count_by_country"
    ) {
      if (item_id === 0 && editDataForFields[property_name]) return true;

      let store_type_name = "";
      if (key === "store_type") {
        store_type_name = storeTypes?.find((item: ISelectItem) => item?.id === item_id)?.name as string;
        new_data = (editDataForFields[property_name]?.data as ITableData[])?.filter(
          (store: ITableData) => (store[key as keyof typeof store] as IStoreSelectItem)!.name === store_type_name
        );
      } else {
        new_data = (editDataForFields[property_name]?.data as ITableData[])?.filter(
          (item: ITableData) => (item[key as keyof typeof item] as IStoreSelectItem)?.id === item_id
        );
      }
    } else {
      new_data = editDataForFields[property_name]?.data as ITableData[];
    }

    const data: ITableData[] = [];
    const years: number[] = [];

    new_data?.sort((a: ITableData, b: ITableData) => (a?.year > b?.year ? -1 : 1));

    new_data?.forEach((data: ITableData) => {
      if (years.indexOf(data?.year) < 0 && data.include) years.push(data?.year);
    });

    const sorted_priorities = editDataForFields[property_name]?.dataSources
      ?.sort((prev, current) => (prev.priority > current.priority ? -1 : 1))
      .map((el) => el.id);

    years.forEach((year: number) => {
      const temp_data: ITableData[] = [];
      new_data.forEach((item: ITableData) => {
        if (item?.year === year && item.include) temp_data.push(item);
      });

      if (temp_data.length > 1) {
        temp_data.sort((a, b) => {
          return (sorted_priorities as number[]).indexOf(a.data_source_id) >
            (sorted_priorities as number[]).indexOf(b.data_source_id)
            ? 1
            : -1;
        });
      }
      if (temp_data && temp_data[0]) data.push(temp_data[0]);
    });

    return data && editDataForFields[property_name] ? data : null;
  };

  const getHighestPriorityQuarterlyData = (property_name = "quarterly_revenue", key?: string, item_id?: number) => {
    let new_data = editDataForFields[property_name]?.data as ITableData[];
    if (item_id === 0 && editDataForFields[property_name]) return true;
    if (key) {
      new_data = (editDataForFields[property_name]?.data as ITableData[])?.filter(
        (item: ITableData) => (item[key as keyof typeof item] as IStoreSelectItem)?.id === item_id && item.include
      );
    }
    const data: ITableData[] = [];
    const years: number[] = [];
    new_data?.sort((a: ITableData, b: ITableData) => {
      if (a.year === b.year && b?.quarter && a?.quarter) {
        return b?.quarter - a?.quarter;
      }
      return b.year > a.year ? 1 : -1;
    });
    new_data?.forEach((data: ITableData) => {
      const time = Number(String(data?.year) + String(data?.quarter));
      if (years.indexOf(time) < 0) years.push(time);
    });
    const sorted_priorities = editDataForFields[property_name]?.dataSources
      ?.sort((prev, current) => (prev.priority > current.priority ? -1 : 1))
      .map((el) => el.id);
    years.forEach((year: number) => {
      const temp_data: ITableData[] = [];
      new_data.map((item: ITableData) => {
        if (Number(String(item?.year) + String(item?.quarter)) === year) temp_data.push(item);
      });

      if (temp_data.length > 1) {
        temp_data.sort((a: ITableData, b: ITableData) => {
          return (sorted_priorities as number[]).indexOf(a.data_source_id) >
            (sorted_priorities as number[]).indexOf(b.data_source_id)
            ? 1
            : -1;
        });
      }

      if (temp_data && temp_data[0]) data.push(temp_data[0]);
    });
    return data?.length ? data : null;
  };

  const getHighestPriorityData = (property_name: string) => {
    const companyPersonFind = COMPANY_PERSON_FIELDS.find((item) => item.field === property_name);
    const rtn_p2p_member = property_name === "rtn_member" || property_name === "p2pi_member";
    if (companyPersonFind) {
      const sortedDataSources = editDataForFields[companyPersonFind.field]?.dataSources.sort(
        (prev, current) => current.priority - prev.priority
      );

      const title = companyPersonFind.title;

      let data: ISingleData | undefined;
      if (sortedDataSources) {
        for (const ds of sortedDataSources) {
          data = (editDataForFields[companyPersonFind.field]?.data as IListData[])?.find(
            (item: IListData) => item.data_source_id === ds.id && item.title === title
          );
          if (data) break;
        }
      }

      if (!data) {
        if (editDataForFields[companyPersonFind.field]) return [];
        return data;
      }
      return {
        sourceId: data?.data_source_id,
        value: data?.full_name,
      };
    } else if (editDataForFields[property_name]) {
      const sortedDataSources = editDataForFields[property_name]?.dataSources.sort(
        (prev, current) => current.priority - prev.priority
      );

      let data = null;

      if (sortedDataSources)
        for (const ds of sortedDataSources) {
          if (property_name === "stock") {
            const fieldEditData: StockAndOwnershipTypeEditData["stock"] | null = editDataForFields[
              property_name
            ] as any;

            const stock = fieldEditData?.data.find((item) => item.dataSourceId === ds?.id);

            if (stock && stock.value.ticker && stock.value.exchange) {
              return {
                sourceId: stock.dataSourceId,
                value: {
                  id: stock.id,
                  ticker: stock.value.ticker,
                  exchange: stock.value.exchange,
                  sourceId: stock.dataSourceId,
                },
              };
            }
          } else if (property_name === "ownershipType") {
            const fieldEditData: StockAndOwnershipTypeEditData["ownershipType"] | null = editDataForFields[
              property_name
            ] as any;

            const ownershipType = fieldEditData?.data.find((item) => item.dataSourceId === ds?.id);

            if (ownershipType && ownershipType.value && ownershipType.value.id) {
              return {
                sourceId: ownershipType.dataSourceId,
                value: {
                  id: ownershipType.value.id,
                  name: ownershipType.value.name,
                },
              };
            }
          } else {
            const found_data = (editDataForFields[property_name] as IEditFieldModalProp<ISingleData>)?.data?.find(
              (item: ISingleData) => item.data_source_id === ds?.id
            );

            // eslint-disable-next-line no-extra-boolean-cast
            if (
              (rtn_p2p_member && !!found_data && found_data?.value !== undefined && found_data?.value !== null) ||
              (!rtn_p2p_member && !!found_data && found_data?.value)
            ) {
              data = found_data;
              break;
            }
          }
        }

      if (data === null) return null;
      return {
        sourceId: (data as ISingleData)?.data_source_id,
        value: (data as ISingleData)?.value,
      };
    }
  };

  const getHighestPriorityDataIndustry = () => {
    if (editDataForFields.industry) {
      const sortedDataSources = editDataForFields.industry?.dataSources.sort(
        (prev, current) => current.priority - prev.priority
      );

      if (sortedDataSources) {
        for (const ds of sortedDataSources) {
          const found_data = (editDataForFields.industry as IEditFieldModalProp<ISingleData>)?.data?.find(
            (item: ISingleData) => item.sourceId === ds?.id
          );
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!found_data) {
            return { data: found_data };
          }
        }
      }
      return { data: null };
    }

    return null;
  };

  const getHighestPriorityDataList = (property_name: string) => {
    if (editDataForFields[property_name]) {
      const highestPriorityDataSource = editDataForFields[property_name]?.dataSources.reduce((prev, current) =>
        prev.priority > current.priority ? prev : current
      );
      let data = null;
      if (property_name === "founders") {
        data = (editDataForFields?.founders as IEditFieldModalProp<IListData>)?.data?.filter(
          (item: IListData) => item.title === "Founder"
        );
      } else {
        data = (editDataForFields[property_name] as IEditFieldModalProp<IListData>)?.data;
      }
      if (!data) return null;
      return {
        sourceId: highestPriorityDataSource?.id,
        value: property_name === "founders" ? data : data.filter((item) => item.include),
      };
    }
  };

  const getHighestPriorityDataHQAddress = () => {
    if (editDataForFields.hq_address) {
      const sortedDataSources = editDataForFields.hq_address?.dataSources.sort(
        (prev, current) => current.priority - prev.priority
      );

      let data = null;

      if (sortedDataSources)
        for (const ds of sortedDataSources) {
          const found_data = (editDataForFields.hq_address as IEditFieldModalProp<ISingleData>)?.data?.find(
            (item: ISingleData) => item.data_source_id === ds?.id
          );
          // eslint-disable-next-line no-extra-boolean-cast
          if (
            // if there is any address information, assign it. if not, return
            (found_data as ISingleData)?.hq_address ||
            (found_data as ISingleData)?.hq_country?.name ||
            (found_data as ISingleData)?.hq_state?.name ||
            (found_data as ISingleData)?.hq_zip
          ) {
            data = found_data;
            break;
          }
        }
      if (data === null) return;

      let addressValue = "";
      if ((data as ISingleData)?.hq_address) addressValue += `${(data as ISingleData)?.hq_address}, `;
      if ((data as ISingleData)?.hq_state?.name) addressValue += `${(data as ISingleData)?.hq_state?.name}, `;
      if ((data as ISingleData)?.hq_country?.name) addressValue += `${(data as ISingleData)?.hq_country?.name}, `;
      if ((data as ISingleData)?.hq_zip) addressValue += `${(data as ISingleData)?.hq_zip}`;

      if (addressValue.endsWith(", ")) addressValue = addressValue.substring(0, addressValue.length - 2);

      return {
        sourceId: (data as ISingleData)?.data_source_id,
        value: addressValue,
      };
    }
  };

  const createExportData = () => {
    const company_data: any = {};

    if (!companyData) {
      return company_data;
    }

    Object.keys(companyData).forEach((key: string) => {
      //no need to add in excel file
      if (
        key === "revenue" ||
        key === "revenue_by_country_quarterly" ||
        key === "yearly_revenue_chart_data" ||
        key === "yearly_revenue" ||
        key === "quarterly_revenue_chart_data" ||
        key === "quarterly_revenue" ||
        key === "store_num" ||
        key === "quarterly_store_num" ||
        key === "quarterly_employee_num" ||
        key === "shopper_count" ||
        key === "headquarter"
      )
        return;

      if (
        companyData[key] === null ||
        companyData[key]?.length === 0 ||
        companyData[key]?.value === "" ||
        companyData[key]?.value?.length === 0
      ) {
        return;
      } else {
        if (Array.isArray(companyData[key]?.value) || Array.isArray(companyData[key])) {
          if (key === "operation_areas") {
            company_data[key] = `${companyData[key]?.value?.length} Countries`;
            return;
          }

          if (key === "founders") {
            let founders_string = "";
            companyData[key]?.value?.forEach((founder: any) => {
              founders_string += `${founder?.full_name},`;
            });
            founders_string = founders_string.slice(0, -1);
            company_data[key] = founders_string;
            return;
          }

          if (key === "executives" || key === "board_members") {
            let board_string = "";
            companyData[key]?.value?.forEach((executive: any) => {
              board_string += `${executive?.full_name},`;
            });
            board_string = board_string.slice(0, -1);
            company_data[key] = board_string;
            return;
          }

          if (key === "secondaryIndustries") {
            company_data.secondaryIndustries = (companyData as ICompany).secondaryIndustries
              ?.map(({ name }) => name)
              .join(",");
            return;
          }

          if (key.startsWith("store_num")) {
            let store_num_string = "";
            companyData[key]?.forEach((store_num: IDataItem) => {
              const { id, value, year, ...rest } = store_num;
              const store_key = Object.keys(rest)[0];
              store_num_string += `${(store_num[store_key as keyof typeof store_num] as IStoreSelectItem)?.name}-${
                store_num?.year
              }: ${store_num?.value}\n`;
            });
            store_num_string = store_num_string.slice(0, -1);
            company_data[key] = store_num_string;
            return;
          }

          if (key === "shopper_count_by_country" || key === "revenue_by_country_yearly") {
            let value_string = "";
            companyData[key]?.value?.forEach((item: IDataItem) => {
              value_string += `${item?.country?.name}-${item?.year}: ${item?.value}\n`;
            });
            value_string = value_string.slice(0, -1);
            company_data[key] = value_string;
            return;
          }

          if (key === "insights") {
            let insights_string = "";
            companyData[key]?.forEach((item: IInsight) => {
              insights_string += `${item?.value},`;
            });
            insights_string = insights_string.slice(0, -1);
            company_data[key] = insights_string;
            return;
          }

          try {
            company_data[key] =
              typeof companyData[key]?.value[0]?.value === "number"
                ? nFormatter(companyData[key]?.value[0]?.value)
                : companyData[key]?.value[0]?.value;
          } catch (e) {
            console.log(key, e);
          }
        } else {
          if (key === "foundationYear") {
            company_data[key] = dateFormatter(companyData[key]?.value);
            return;
          }

          if (key === "modified_at") {
            company_data[key] = dateFormatter(companyData[key]);
            return;
          }

          if (key === "ceo" || key === "owner") {
            company_data[key] = `${companyData[key]?.value?.full_name}`;
            return;
          }

          if (key === "currency") {
            company_data[key] = `${companyData[key]?.value?.name}`;
            return;
          }

          if (key === "rtn_member" || key === "p2pi_member") {
            company_data[key] = String(companyData[key]?.value).toUpperCase();
            return;
          }

          if (key === "alternative_names") {
            let alternative_names_string = "";
            alternative_names_string += `English Names: ${companyData[key]?.value?.english_names?.length}\nNon-English Names:  ${companyData[key]?.value?.non_english_names?.length} `;
            company_data[key] = alternative_names_string;
            return;
          }

          if (key === "subsidiaries") {
            let subsidiaries_string = "";
            const storedSubsidiariesCount = companyData[key].filter((x: any) => x.id).length;
            const totalSubsidiariesCount = companyData[key].length;

            subsidiaries_string += `Stored Subsidiaries: ${storedSubsidiariesCount}\nSubsidiaries:  ${totalSubsidiariesCount} `;
            company_data[key] = subsidiaries_string;
            return;
          }

          if (key === "headquarter_address") {
            let address_string = "";
            if (companyData[key]?.value?.hq_address) address_string += `${companyData[key]?.value?.hq_address}, `;
            if (companyData[key]?.value?.zip) address_string += `${companyData[key]?.value?.zip}, `;
            if (companyData[key]?.value?.state) address_string += `${companyData[key]?.value?.state}, `;
            if (companyData[key]?.value?.country) address_string += `${companyData[key]?.value?.country}`;

            if (address_string.endsWith(", ")) address_string = address_string.substring(0, address_string.length - 2);
            company_data[key] = address_string;
            return;
          }

          company_data[key] = companyData[key]?.value || companyData[key]?.range?.name || companyData[key];
        }
      }
    });

    return company_data;
  };

  const exportToExcel = async () => {
    const export_data = createExportData();
    const export_data_array = [];
    export_data_array.push(export_data);

    await excelExport.export(companyData?.name?.value, export_data_array);
  };

  useEffect(() => {
    if (id && token) {
      fetchData(id, token).then(({ companyData, dataSources }) => {
        setCompanyData(companyData);
        setDataSources(dataSources);
      });

      Object.keys(selectedItemsForStores)?.forEach((key: string) => {
        fetchStoresItems(`${apiConfig.storeSelectedItems}/${id}/${key}`).then((response) => {
          setSelectedItemsForStores((selectedItemsForStores) => ({ ...selectedItemsForStores, [key]: response?.data }));
        });
      });

      fetchStoresItems(`${apiConfig.storeTypes}`).then((response) => {
        setStoreTypes(response);
      });
      getCompanyField("shopper_count_selected_countries").then((response) => {
        setEditDataForFields({ ...editDataForFields, shopper_count_selected_countries: response });
      });

      getCompanyField("revenue_selected_countries").then((response) => {
        setEditDataForFields({ ...editDataForFields, revenue_selected_countries: response });
      });
    }
  }, [id, refresh]);

  return (
    <div style={{ marginTop: "-20px" }}>
      {isEdit &&
        openedModal.isVisible &&
        (openedModal.type === "single" ? (
          <SingleFieldEditModal
            name={openedModal.name}
            property_name={openedModal.property_name}
            input_type={openedModal.input_type}
            property_edit_data_for_fields={
              editDataForFields[openedModal.property_name] as IEditFieldModalProp<ISingleData>
            }
            handleSaveEdit={handleSaveEdit}
            get_select_data_function={openedModal.get_select_data_function}
            isVisible={true}
            title={openedModal.title}
            cancel={cancelEditModal}
            companyId={Number(id)}
            value_text={openedModal.value_text}
            summary_text={openedModal.summary_text}
            select_key={openedModal.select_key}
          />
        ) : openedModal.type === "list" ? (
          <ListFieldEditModal
            name={openedModal.name}
            property_name={openedModal.property_name}
            input_type={openedModal.input_type}
            property_edit_data_for_fields={
              editDataForFields[openedModal.property_name] as IEditFieldModalProp<IListData>
            }
            handleSaveEdit={handleSaveEdit}
            isVisible={true}
            title={openedModal.title}
            cancel={cancelEditModal}
            companyId={Number(id)}
            value_text={openedModal.value_text}
            summary_text={openedModal.summary_text}
            noShowPersonTitle={openedModal.noShowPersonTitle}
            noShowBoardMember={openedModal.noShowBoardMember}
            noShowInclude={openedModal.noShowInclude}
            get_select_data_function={openedModal.get_select_data_function}
          />
        ) : openedModal.type === "subsidiaries" ? (
          <EditSubsidiariesModal
            editFieldData={editDataForFields[openedModal.property_name] as any}
            onSave={(value) => handleSaveEdit("subsidiaries", value as any)}
            onClose={cancelEditModal}
            companyId={Number(id)}
          />
        ) : openedModal.type === "stock-and-ownershipType" ? (
          <EditStockAndOwnershipTypeModal
            editFieldData={
              editDataForFields["stock"]
                ? ({
                    stock: editDataForFields["stock"],
                    ownershipType: editDataForFields["ownershipType"],
                  } as any)
                : null
            }
            onSave={(value) => handleSaveStockAndOwnershipType(value)}
            onClose={cancelEditModal}
            companyId={Number(id)}
          />
        ) : (
          <TableFieldEditModal
            name={openedModal.name}
            property_name={openedModal.property_name}
            property_edit_data_for_fields={
              editDataForFields[openedModal.property_name] as IEditFieldModalProp<ITableData>
            }
            select_data_for_fields={
              openedModal.selected_property_name
                ? (editDataForFields[openedModal.selected_property_name] as IEditFieldModalProp<ISelectData>)
                : null
            }
            input_type={openedModal.input_type}
            handleSaveEdit={handleSaveEdit}
            data_type={openedModal.data_type!}
            isVisible={true}
            select_key={openedModal.select_key as string}
            cancel={cancelEditModal}
            companyId={Number(id)}
            editModalFunctions={editModalFunctions}
            selected_items={selectedItemsForStores[openedModal.select_key as string]}
            selected_property_name={openedModal.selected_property_name}
          />
        ))}
      <div className={classes.banner}>
        <CompanyBanner
          data={companyData}
          isEdit={isEdit}
          setIsEdit={edit}
          preview={preview}
          editModalFunctions={editModalFunctions}
          getHighestPriorityData={getHighestPriorityData}
          getHighestPriorityDataHQAddress={getHighestPriorityDataHQAddress}
          getDataSource={getDataSource}
        />
      </div>
      <div className={classes.body}>
        <div className={`${classes.leftNavigation} no-print`}>
          <NavigationBar sectionNames={NAV_SECTIONS} scrollToSection={scrollToSection} />
        </div>
        <div>
          <div
            style={{
              marginLeft: "auto",
              width: "max-content",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <DropdownMenu title="Export Company Info" icon={ExportIcon}>
              <div className="no-print">
                <button className={classes.dropdownButton} onClick={exportToExcel}>
                  Export to Excel
                </button>
                <button
                  className={classes.dropdownButton}
                  onClick={() => {
                    window.print();
                  }}
                >
                  Export to PDF
                </button>
              </div>
            </DropdownMenu>

            {companyData?.modified_at && (
              <div className={classes.lastEditedDate}>
                Last edited date : {moment(companyData.modified_at).format("DD-MM-YYYY HH:mm:SS")}
              </div>
            )}
          </div>
          <SectionCard title="Company Overview">
            <div id="overview" ref={(el) => (itemRefs.current[0] = el)}>
              <CompanyOverview
                data={companyData}
                editDataForFields={editDataForFields}
                selectedItems={selectedItemsForStores}
                isEdit={isEdit}
                getDataSource={getDataSource}
                editModalFunctions={editModalFunctions}
                getHighestPriorityData={getHighestPriorityData}
                getHighestPriorityDataIndustry={getHighestPriorityDataIndustry}
                getHighestPriorityDataHQAddress={getHighestPriorityDataHQAddress}
                getHighestPriorityDataList={getHighestPriorityDataList}
                getHighestPriorityQuarterlyData={getHighestPriorityQuarterlyData}
                getHighestPriorityYearlyData={getHighestPriorityYearlyData}
              />
            </div>
          </SectionCard>
          <SectionCard title="Alternative names and subsidiaries" className="no-print">
            <div id="alternative" ref={(el) => (itemRefs.current[1] = el)}>
              <AlternativeNames
                data={companyData}
                isEdit={isEdit}
                getDataSource={getDataSource}
                editModalFunctions={editModalFunctions}
                getHighestPriorityDataList={getHighestPriorityDataList}
              />
            </div>
          </SectionCard>
          <SectionCard title="Additional Domain names/websites">
            <div id="acompany-domains" style={{ marginBottom: "30px" }}>
              <CompanyDomainsTable
                title="Additional Domain names/websites"
                headers={[{ title: "Link", width: "100%" }]}
                rows={(Array.isArray(getHighestPriorityDataList("company_domains")?.value)
                  ? (getHighestPriorityDataList("company_domains")?.value as IListData[])
                  : companyData?.company_domains?.value?.length > 0
                  ? (companyData?.company_domains?.value as [])
                  : []
                )?.map((item: any) => ({ year: item.year, link: item.value }))}
                source="company_domains"
                editFunction={editModalFunctions.openCompanyDomainsEdit}
                isEdit={isEdit}
              />
            </div>
          </SectionCard>
          <SectionCard title="Annual reports">
            <div id="annual-reports" style={{ marginBottom: "30px" }}>
              <AnnualReportsTable
                title="Annual reports"
                headers={[
                  { title: "Year", width: "40%" },
                  { title: "Link", width: "60%" },
                ]}
                rows={(Array.isArray(getHighestPriorityYearlyData("annual_reports"))
                  ? (getHighestPriorityYearlyData("annual_reports") as [])
                  : companyData?.annual_reports?.value?.length > 0
                  ? (companyData?.annual_reports?.value as [])
                  : []
                )?.map((item: any) => ({ year: item.year, link: item.value }))}
                source="annual_reports"
                editFunction={editModalFunctions.openAnnualReportsEdit}
                isEdit={isEdit}
              />
            </div>
          </SectionCard>
          <SectionCard title="Strategy, Marketing, Vision">
            <div id="strategy" ref={(el) => (itemRefs.current[3] = el)}>
              <InsightsSection
                data={
                  editDataForFields.insights
                    ? editDataForFields
                    : (companyData as {
                        insights?: InsightItem[];
                      })
                }
                saveInsights={(insights) => handleSaveEdit("insights", insights as any)}
                isEdit={isEdit}
              />
            </div>
          </SectionCard>
          <SectionCard title="Revenue and Revenue Range" style={{ backgroundColor: "#F6F6F6", border: "none" }}>
            <div id="revenue" ref={(el) => (itemRefs.current[4] = el)}>
              <RevenueRange
                data={companyData}
                editModalFunctions={editModalFunctions}
                getHighestPriorityQuarterlyData={getHighestPriorityQuarterlyData}
                getHighestPriorityYearlyData={getHighestPriorityYearlyData}
                getDataSource={getDataSource}
              />
            </div>
          </SectionCard>
          <SectionCard title="News">
            <div id="news" ref={(el) => (itemRefs.current[6] = el)}>
              <News data={companyData} />
            </div>
          </SectionCard>
          <SectionCard title="Additional Links">
            <div id="links" ref={(el) => (itemRefs.current[7] = el)}>
              <AdditionalLinks data={companyData} />
            </div>
          </SectionCard>
        </div>
      </div>
      {(isEdit || preview) && (
        <div className={classes.saveSection}>
          <button
            className={classes.button}
            style={{
              marginLeft: "120px",
              backgroundColor: "#4D748D",
              color: "#FFFFFF",
              width: preview ? "150px" : "105px",
            }}
            onClick={seePreview}
          >
            {preview ? "Cancel preview" : "See preview"}
          </button>
          <div className={classes.actionButtons}>
            <ClearFilters text="Cancel" clear={cancel} />
            <button
              className={classes.button}
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                backgroundColor: "#FFFFFF",
                color: "#4D748D",
                width: "157px",
              }}
              title="action not implemented"
            >
              Show version history
            </button>
            <ApplyButton text="Save" apply={save} />
          </div>
        </div>
      )}
      <ModalComponent title="" open={messageModal} setOpen={(data: boolean) => setMessageModal(data)}>
        <div>
          <span className={classes.errorDiv}>{errorMessage}</span>
        </div>
      </ModalComponent>
    </div>
  );
};
