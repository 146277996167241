import { Dispatch, SetStateAction } from "react";
import { InputTypeEnums, IOpenedModal } from "../pages/companyView/types";
import { apiConfig } from "../services/api/apiConfig";
import { Service } from "../services/api/service";

export default class EditModalFunctions implements ICompanyEditModalFunctions {
  isEdit: boolean;
  setOpenedModal: Dispatch<SetStateAction<IOpenedModal>>;

  constructor(isEdit: boolean, setOpenedModal: Dispatch<SetStateAction<IOpenedModal>>) {
    this.isEdit = isEdit;
    this.setOpenedModal = setOpenedModal;
  }

  openSummaryEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Summary",
      type: "single",
      input_type: InputTypeEnums.text_area_input,
      property_name: "summary",
      isVisible: true,
    });

  openIndustryEdit = async () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Industry",
      type: "single",
      input_type: InputTypeEnums.multi_level_select_input,
      property_name: "industry", // TO DO: fix with new response data
      isVisible: true,
      get_select_data_function: (token: string) => Service.get(apiConfig.taxonomyDBMarketSegments, token),
    });

  openSecondaryIndustriesEdit = async () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Secondary Industries",
      type: "list",
      input_type: InputTypeEnums.multi_level_select_input,
      property_name: "secondary_industries", // TO DO: fix with new response data
      isVisible: true,
      get_select_data_function: (token: string) => Service.get(apiConfig.taxonomyDBMarketSegments, token),
    });

  openNaceClassificationEdit = () => {
    this.isEdit &&
      this.setOpenedModal({
        name: "NACE Industry Codes",
        type: "list",
        input_type: InputTypeEnums.string_input,
        property_name: "naceClassification",
        isVisible: true,
      });
  };

  openNaicsClassificationEdit = () => {
    this.isEdit &&
      this.setOpenedModal({
        name: "NAICS Industry Codes",
        type: "list",
        input_type: InputTypeEnums.string_input,
        property_name: "naicsClassification",
        isVisible: true,
      });
  };

  openIsicClassificationEdit = () => {
    this.isEdit &&
      this.setOpenedModal({
        name: "ISIC Industry Codes",
        type: "list",
        input_type: InputTypeEnums.string_input,
        property_name: "isicClassification",
        isVisible: true,
      });
  };

  openStoresEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Stores",
      type: "table",
      input_type: InputTypeEnums.null,
      property_name: "stores",
      isVisible: true,
      data_type: "year",
    });
  openCountryStoresEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Stores By Country",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "store_num_by_country",
      isVisible: true,
      data_type: "year",
      select_key: "country",
    });

  openStateStoresEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Stores By State/Province",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "store_num_by_state",
      isVisible: true,
      data_type: "year",
      select_key: "state",
    });

  openTypeStoresEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Stores By Type",
      type: "table",
      input_type: InputTypeEnums.string_input,
      property_name: "store_num_by_store_type",
      isVisible: true,
      data_type: "year",
      select_key: "store_type",
    });

  openEmployeesEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Employee Number",
      type: "table",
      input_type: InputTypeEnums.number_input,
      property_name: "employee_num",
      isVisible: true,
      data_type: "year",
    });

  openDateOfCreationEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Date Founded",
      type: "single",
      input_type: InputTypeEnums.date_input,
      property_name: "foundation_year",
      isVisible: true,
    });

  openFoundersEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Founders",
      type: "list",
      title: "Founder",
      input_type: InputTypeEnums.key_persons_input,
      property_name: "founders",
      isVisible: true,
      noShowPersonTitle: true,
      noShowBoardMember: true,
      noShowInclude: true,
      value_text: "Name",
      summary_text: "Description",
    });

  openChairmanEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Chairman",
      type: "single",
      title: "Chairman",
      input_type: InputTypeEnums.key_persons_input,
      property_name: "chairman",
      isVisible: true,
      value_text: "Name",
      summary_text: "Description",
    });
  openCeoEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Ceo",
      type: "single",
      title: "CEO",
      input_type: InputTypeEnums.key_persons_input,
      property_name: "ceo",
      isVisible: true,
      value_text: "Name",
      summary_text: "Description",
    });
  openBoardEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Board",
      type: "list",
      input_type: InputTypeEnums.key_persons_input,
      property_name: "board_members",
      isVisible: true,
      // noShowPersonTitle: true,
      noShowBoardMember: true,
      value_text: "Name",
      summary_text: "Description",
    });
  openKeyPersonsEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Executives",
      type: "list",
      input_type: InputTypeEnums.key_persons_input,
      property_name: "executives",
      isVisible: true,
      value_text: "Name",
      summary_text: "Description",
    });
  openOwnerEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Owner",
      type: "single",
      title: "Owner",
      input_type: InputTypeEnums.key_persons_input,
      property_name: "owner",
      isVisible: true,
      value_text: "Name",
      summary_text: "Description",
      noShowInclude: true,
    });

  openHeadquarterEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Headquarter",
      type: "single",
      input_type: InputTypeEnums.address_input,
      property_name: "hq_address",
      isVisible: true,
    });

  openDomainEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Website",
      type: "single",
      input_type: InputTypeEnums.string_input,
      property_name: "domain",
      isVisible: true,
    });

  openLinkedinEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Linkedin",
      type: "single",
      input_type: InputTypeEnums.string_input,
      property_name: "linkedin",
      isVisible: true,
    });
  openNameEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Name",
      type: "single",
      input_type: InputTypeEnums.string_input,
      property_name: "name",
      isVisible: true,
    });
  openLogoEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Logo",
      type: "single",
      input_type: InputTypeEnums.string_input,
      property_name: "logo",
      isVisible: true,
    });

  // openMonthlyRevenueEdit = () =>
  //   this.isEdit &&
  //   this.setOpenedModal({
  //     name: "Revenue and Revenue Range",
  //     type: "table",
  //     input_type: InputTypeEnums.null,
  //     property_name: "monthly_revenue",
  //     isVisible: true,
  //     data_type: "month",
  //   });
  openQuarterlyRevenueEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Revenue and Revenue Range Quarterly",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "quarterly_revenue",
      isVisible: true,
      data_type: "quarter",
    });
  openQuarterlyRevenueCountryEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Revenue and Revenue Range By Country",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "revenue_by_country_quarterly",
      selected_property_name: "revenue_selected_countries",
      isVisible: true,
      data_type: "quarter",
      select_key: "country",
    });

  openYearlyRevenueEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Revenue Yearly",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "yearly_revenue",
      isVisible: true,
      data_type: "year",
    });
  openYearlyRevenueByCountryEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Revenue and Revenue Range By Country",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "revenue_by_country_yearly",
      selected_property_name: "revenue_selected_countries",
      isVisible: true,
      data_type: "year",
      select_key: "country",
    });

  openSalesEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Sales",
      type: "table",
      input_type: InputTypeEnums.number_input,
      property_name: "sales",
      isVisible: true,
      data_type: "year",
    });

  openAlternativeNamesEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Alternative Names",
      type: "list",
      input_type: InputTypeEnums.string_input,
      property_name: "alternative_names",
      isVisible: true,
    });

  openSubsidiariesEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Subsidiaries",
      // type: "list",
      type: "subsidiaries",
      input_type: InputTypeEnums.subsidiaries_input,
      property_name: "subsidiaries",
      isVisible: true,
    });

  openShopperCountEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Shopper Count",
      type: "single",
      input_type: InputTypeEnums.number_input,
      property_name: "shopper_count",
      isVisible: true,
    });
  openShopperCountByCountryEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Shopper Count By Country",
      type: "table",
      input_type: InputTypeEnums.select_input,
      property_name: "shopper_count_by_country",
      selected_property_name: "shopper_count_selected_countries",
      isVisible: true,
      data_type: "year",
      select_key: "country",
    });

  openRTNMember = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "RTN Member",
      type: "single",
      input_type: InputTypeEnums.select_input,
      property_name: "rtn_member",
      isVisible: true,
      select_key: "boolean",
    });

  openStock = () => {
    this.isEdit &&
      this.setOpenedModal({
        name: "Stock Ticker",
        type: "single",
        input_type: InputTypeEnums.stock_input,
        property_name: "stock",
        isVisible: true,
      });
  };

  openP2PMember = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "P2P Member",
      type: "single",
      input_type: InputTypeEnums.select_input,
      property_name: "p2pi_member",
      isVisible: true,
      select_key: "boolean",
    });

  openPhoneNumberEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Phone Number",
      type: "single",
      input_type: InputTypeEnums.phone_input,
      property_name: "phone",
      isVisible: true,
    });

  openAnnualReportsEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Annual Reports",
      type: "table",
      input_type: InputTypeEnums.string_input,
      noShowInclude: true,
      property_name: "annual_reports",
      isVisible: true,
      data_type: "year",
    });

  openCompanyDomainsEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Additional Domains",
      type: "list",
      input_type: InputTypeEnums.string_input,
      property_name: "company_domains",
      isVisible: true,
    });
  openOperationAreasEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Acitivities Location",
      type: "list",
      input_type: InputTypeEnums.multi_select_select_input,
      property_name: "operation_areas",
      isVisible: true,
      get_select_data_function: (token: string) => Service.get(apiConfig.countries, token),
    });

  openStockAndOwnershipTypeEdit = () =>
    this.isEdit &&
    this.setOpenedModal({
      name: "Stock & Ownership Type",

      type: "stock-and-ownershipType",
      input_type: InputTypeEnums.stock_input,
      property_name: "stock",
      isVisible: true,
    });
}

export interface ICompanyEditModalFunctions {
  isEdit: boolean;
  setOpenedModal: Dispatch<SetStateAction<IOpenedModal>>;

  openStockAndOwnershipTypeEdit: () => void;
  // openStock: () => void;

  openSummaryEdit: () => void;
  openIndustryEdit: () => void;
  openNaceClassificationEdit: () => void;
  openNaicsClassificationEdit: () => void;
  openIsicClassificationEdit: () => void;
  openSecondaryIndustriesEdit: () => void;
  openStoresEdit: () => void;
  openCountryStoresEdit: () => void;
  openStateStoresEdit: () => void;
  openTypeStoresEdit: () => void;
  openEmployeesEdit: () => void;
  openDateOfCreationEdit: () => void;
  openFoundersEdit: () => void;
  openChairmanEdit: () => void;
  openCeoEdit: () => void;
  openBoardEdit: () => void;
  openKeyPersonsEdit: () => void;
  openOwnerEdit: () => void;
  openHeadquarterEdit: () => void;
  openDomainEdit: () => void;
  openLinkedinEdit: () => void;
  openYearlyRevenueEdit: () => void;
  openYearlyRevenueByCountryEdit: () => void;
  // openMonthlyRevenueEdit: () => void;
  openQuarterlyRevenueEdit: () => void;
  openQuarterlyRevenueCountryEdit: () => void;
  openSalesEdit: () => void;
  openAlternativeNamesEdit: () => void;
  openSubsidiariesEdit: () => void;
  openNameEdit: () => void;
  openShopperCountEdit: () => void;
  openShopperCountByCountryEdit: () => void;
  openRTNMember: () => void;
  openP2PMember: () => void;
  openPhoneNumberEdit: () => void;
  openAnnualReportsEdit: () => void;
  openCompanyDomainsEdit: () => void;
  openOperationAreasEdit: () => void;
  openLogoEdit: () => void;
}
